import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { SearchBoxProvided, SearchBoxExposed } from "react-instantsearch-core"
import { makeStyles } from "makeStyles"
import { FormControl, InputAdornment, OutlinedInput, IconButton } from "@mui/material"

import { IoIosSearch } from "react-icons/io"

const useStyles = makeStyles()(() => ({
	textField: {
		width: "100%",
		background: "rgba(255, 255, 255, 0.15)",
		color: "white",
		borderRadius: "50px",
		outline: "none",
		border: "none",
	},
	"@media only screen and (min-width: 960px) and (max-width: 1150px)": {
		textField: {
			width: "100%",
			maxWidth: 400,
			margin: "auto",
			background: "rgba(255, 255, 255, 0.15)",
			borderRadius: 25,
			fontSize: "13px",
		},
	},
	inputStyle: {
		color: "white",
		zIndex: 0,
		opacity: 1,
		fontSize: "13px",
		borderRadius: "50px",
		padding: "0px 20px 0px 10px",
		outline: "none",
		height: 44,
	},
	"@media only screen and (max-width: 1150px)": {
		inputStyle: {
			fontSize: 13,
			borderRadius: 50,
			padding: "0px 20px 0px 10px",
		},
	},
	// mobile-tablet
	"@media only screen and (min-width: 960px)": {
		textField: {
			maxWidth: 550,
			marginLeft: "-50px",
			marginTop: "0px",
		},
	},
	"@media only screen and (max-width: 960px)": {
		textField: {
			borderRadius: 50,
		},
		inputStyle: {
			fontSize: 13,
			borderRadius: 50,
			padding: "0px 20px 0px 10px",
		},
	},
	searchIcon: {
		background: "#009FB8",
		padding: "4px",
		marginRight: "-9px",
		"@media only screen and (max-width: 1150px)": {
			padding: 5,
			marginRight: -8,
			marginTop: -3,
		},
	},
}))

const SearchBoxInput = ({
	onSubmit,
	searchQuery,
	setSearchQuery,
	toggleHeader,
}: Props & SearchBoxProvided) => {
	const { classes } = useStyles()

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = e.target
		setSearchQuery(value)
	}

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { keyCode, which } = e
		if (keyCode === 13 || which === 13) onSubmit()
	}

	return (
		<FormControl className={classes.textField} variant="filled">
			<OutlinedInput
				id="search-box"
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={onSubmit} className={classes.searchIcon} size="large">
							<IoIosSearch size="1.2rem" color="white" />
						</IconButton>
					</InputAdornment>
				}
				onChange={onChange}
				onClick={toggleHeader}
				value={searchQuery}
				inputProps={{ onKeyPress, "data-hj-whitelist": true, "data-hj-allow": true }}
				placeholder="Wonach suchen Sie?"
				className={classes.inputStyle}
			/>
		</FormControl>
	)
}
interface Props {
	onSubmit: () => void
	searchQuery: string
	setSearchQuery: (searchQuery: string) => void
	toggleHeader: () => void
}

const ExposedSearchBoxInput: React.ComponentClass<Props & SearchBoxExposed> =
	connectSearchBox(SearchBoxInput)

export default ExposedSearchBoxInput
