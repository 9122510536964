import React, { useState, useEffect } from "react"
import useToggle from "@secureo/common/hooks/useToggle"
import { Theme, Box, Snackbar, Switch, FormControlLabel, IconButton, Collapse } from "@mui/material"
import { makeStyles } from "makeStyles"

import Icon from "@mdi/react"
import { mdiCookie } from "@mdi/js"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import Link from "./i18n/Link"

const localStorageKey = "hasClickedAwayCookieBanner"

const hasClickedAwayCookieBanner = () => {
	const hasClickedAwayCookieBanner = localStorage.getItem(localStorageKey) === "true"
	return hasClickedAwayCookieBanner
}

const setHasClickedAwayCookieBanner = () => {
	localStorage.setItem(localStorageKey, "true")
}

const useStyles = makeStyles()((theme: Theme) => ({
	root: {
		borderRadius: 0,
		background: "#01B2C4",
	},
	close: {
		padding: theme.spacing(0.5),
	},
	button: {
		border: "none",
		borderRadius: 0,
		outline: "none",
		background: "whitesmoke",
		padding: "7px 12px",
		cursor: "pointer",
	},
}))

const specificCookies = [
	{
		name: "Essenziell",
		description:
			"Diese Technologien sind erforderlich, um auf unserer Plattform zu navigieren und ihre Kernfunktionen zu nutzen. Ohne die Verwendung solcher cookies kann das ordnungsgemäße Funktionieren unserer Plattform nicht garantiert werden. Sie können diese Technologien nicht deaktivieren.",
		disabled: true,
	},
	{
		name: "Funktionell",
		description:
			"Diese Technologien speichern Ihre Informationen, um wichtige, wenn nicht unbedingt erforderliche Funktionen zu erfüllen. Sie ermöglichen es uns beispielsweise, Ihre Benutzererfahrung und Ihr Serviceangebot anzupassen, ermöglichen uns die kontinuierliche Verbesserung der von uns angebotenen Services durch das Sammeln und Analysieren von Daten zum Webverkehr oder helfen Ihnen, genau das zu finden, wonach Sie suchen.",
		disabled: false,
	},
	{
		name: "Marketing",
		description:
			"Diese Technologien werden verwendet, um personalisierte Online-Werbung für unsere Dienste bereitzustellen, die auf Ihre spezifischen Interessen ausgerichtet ist. Diese Technologien werden verwendet, um Ihnen Anzeigen nicht nur auf unseren eigenen Plattformen anzuzeigen, sondern auch auf Websites und Apps von Drittanbietern, die von Publishern gehostet werden, die nicht zu unserer Unternehmensgruppe gehören. Sie dienen auch dazu, Ihnen Sonderangebote und Werbeaktionen zu unterbreiten, die Ihren Vorlieben entsprechen.",
		disabled: false,
	},
]

const CookieBanner = () => {
	const { classes } = useStyles()
	const [showCookieBanner, setShowCookieBanner] = useState(false)
	const [settingsView, toggleSettingsView] = useToggle(false)

	const [cookieDescription, setOpenCookieDescription] = useState(0)

	useEffect(() => {
		if (!hasClickedAwayCookieBanner()) {
			setShowCookieBanner(true)
		}
	}, [])

	const handleClose = () => {
		setHasClickedAwayCookieBanner()
		setShowCookieBanner(false)
	}

	if (!showCookieBanner) return null

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={showCookieBanner}
			onClose={handleClose}
			ContentProps={{
				"aria-describedby": "message-id",
				className: classes.root,
			}}
			ClickAwayListenerProps={{ onClickAway: () => null }}
			message={
				<Box p={1} className={classes.root} style={{ alignItems: "center" }}>
					{settingsView ? (
						<div style={{ maxWidth: 370 }}>
							{specificCookies.map((cookie, index) => {
								const { name, description, disabled } = cookie

								return (
									<div
										key={index}
										style={{
											paddingBottom: 10,
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												background: "rgba(0, 0, 0, 0.1)",
												width: "100%",
												minWidth: 300,
												borderRadius: 3,
												marginBottom: 10,
											}}
										>
											<FormControlLabel
												control={
													<Switch defaultChecked color="secondary" />
												}
												label={name}
												style={{
													fontSize: "1.2rem",
													width: "100%",
													marginLeft: 0,
												}}
												disabled={disabled}
											/>
											<div>
												<IconButton
													key="close"
													aria-label="close"
													color="inherit"
													size="large"
													style={{ marginRight: 10, outline: "none" }}
													onClick={() => setOpenCookieDescription(index)}
												>
													{cookieDescription === index ? (
														<BsChevronUp size="1.2rem" />
													) : (
														<BsChevronDown size="1.2rem" />
													)}
												</IconButton>
											</div>
										</div>
										<Collapse in={cookieDescription === index}>
											<div style={{ fontSize: "0.8rem" }}>{description}</div>
										</Collapse>
									</div>
								)
							})}
						</div>
					) : (
						<>
							<Icon path={mdiCookie} size={1.7} color="#f7fafa" />
							<Box mt={1}>
								<div id="message-id" style={{ maxWidth: "370px" }}>
									Cookies helfen uns bei der Bereitstellung unserer Dienste. Durch
									die Nutzung unserer Dienste erklären Sie sich damit
									einverstanden, dass wir Cookies setzen. <br />
									<Link
										href="/content/datenschutz"
										style={{
											color: "#000",
											display: "flex",
											alignItems: "center",
											marginTop: 20,
										}}
									>
										Weitere Informationen
									</Link>
								</div>
							</Box>
						</>
					)}
					<Box mt={2} style={{ display: "flex", alignItems: "center" }}>
						<button
							className={classes.button}
							onClick={handleClose}
							style={{
								marginRight: 15,
								background: "#01B2C4",
								color: "#fff",
								border: "1px solid #fff",
							}}
						>
							Einverstanden
						</button>
						<button className={classes.button} onClick={toggleSettingsView}>
							{settingsView ? "Zurück" : "Einstellungen"}
						</button>
					</Box>
				</Box>
			}
		/>
	)
}

export default CookieBanner
