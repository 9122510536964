import React, { useEffect, useState } from "react"
import { Grid, Box, IconButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import Price from "components/Price"
import { connectHits } from "react-instantsearch-dom"
import { ConnectedComponentClass, HitsProvided } from "react-instantsearch-core"

import Link from "components/i18n/Link"
import Spinner from "components/Spinner"

import { AlgoliaProductHit } from "@secureo/common/typings/Algolia"

import NoResults from "svg/no-results.svg"
import searchConfig from "config/searchConfig"

import { trackGA4SelectItem, trackProductHitClick } from "@secureo/common/utils/tracking/productHit"
import LazyLoadedImage from "components/LazyLoadedImage"
import useProductHitImpressionTracking from "@secureo/common/hooks/tracking/useProductHitImpressionTracking"

const useStyles = makeStyles()(() => ({
	productArrow: {
		padding: 10,
		height: 40,
		width: 40,
		color: "#263739",
		transition: "0.5s",
		background: "none",
		opacity: 0,
	},
	name: {
		height: "auto",
		marginTop: 10,
		fontSize: 15,
	},
	card: {
		boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10)",
		borderRadius: 5,
		border: "1px solid white",
		transition: "0.5s",
		"&:hover": {
			border: "1px solid #4691CE",
			boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.20)",
			transition: "0.5s",
			"& $productArrow": {
				opacity: 1,
				padding: 10,
				height: 40,
				width: 40,
			},
		},
	},
}))

const SearchBoxResult = ({ hit, position, closeSearchBar }: SearchBoxResultProps) => {
	const { classes } = useStyles()
	const { name, slug, image, grossPriceCents, manufacturer, variantSku } = hit
	const cutProductName = (name = "") => (name.length > 45 ? `${name.substring(0, 45)} ...` : name)

	const trackProductHitImpression = useProductHitImpressionTracking(
		hit,
		"Search",
		"Search",
		position,
	)

	const onClick = () => {
		trackProductHitClick(name, grossPriceCents, manufacturer, "Search", "Search", position)
		trackGA4SelectItem(name, grossPriceCents, manufacturer, "Search", "Search", position)
		closeSearchBar()
	}

	const slugWithVariantQuery = `${slug}${variantSku ? "?variant=" + variantSku : ""}`

	return (
		<Link href={`/p/${slugWithVariantQuery}`} style={{ color: "#000" }} onClick={onClick}>
			<Box py={1} px={1} className={classes.card}>
				<div style={{ display: "flex" }}>
					<Box mr={2}>
						<LazyLoadedImage
							alt={name}
							title={name}
							src={image}
							onIntersection={trackProductHitImpression}
							style={{ width: 60, height: 60, objectFit: "contain" }}
						/>
					</Box>
					<Box
						style={{
							width: "100%",
						}}
					>
						<div className={classes.name}>
							<span>{cutProductName(name)}</span>
						</div>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<strong>
									<Price priceInEuroCents={grossPriceCents} />
								</strong>
							</div>
							<IconButton disableRipple className={classes.productArrow} size="large">
								&#10141;
							</IconButton>
						</Box>
					</Box>
				</div>
			</Box>
		</Link>
	)
}

interface SearchBoxResultProps {
	hit: AlgoliaProductHit
	position: number
	closeSearchBar: () => void
}

const SearchBoxResults = ({
	hits,
	searchQuery,
	closeSearchBar,
}: HitsProvided<AlgoliaProductHit> & Props) => {
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let timeOutId = null
		setIsLoading(true)
		timeOutId = setTimeout(() => {
			setIsLoading(false)
		}, searchConfig.timeoutBeforeNoResultsDisplayMs)
		return () => {
			clearTimeout(timeOutId)
		}
	}, [searchQuery])

	return (
		<Grid container spacing={2}>
			{hits.map((hit, position) => {
				return (
					<Grid item xs={12} sm={6} md={4} key={hit.objectID}>
						<SearchBoxResult
							hit={hit}
							position={position + 1}
							closeSearchBar={closeSearchBar}
						/>
					</Grid>
				)
			})}
			{!isLoading && hits.length === 0 && (
				<Grid item xs={12}>
					<Box pt={6} style={{ textAlign: "center" }}>
						<NoResults style={{ width: "100%", maxHeight: 200 }} />
						<Box mt={2}>
							Es wurden <strong>keine Ergebnisse</strong> zu Ihrer Suchanfrage
							gefunden.
						</Box>
					</Box>
				</Grid>
			)}
			{isLoading && hits.length === 0 && <Spinner />}
		</Grid>
	)
}

interface Props {
	searchQuery: string
	closeSearchBar: () => void
}

const ExposedSearchBoxResults: ConnectedComponentClass<
	Record<string, unknown>,
	HitsProvided<AlgoliaProductHit>
> = connectHits(SearchBoxResults)

export default ExposedSearchBoxResults
