import React from "react"
import { connectRefinementList } from "react-instantsearch-dom"
import { SearchState } from "react-instantsearch-core"

// https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#virtual-widgets
const VirtualRefinementList = connectRefinementList(() => null)

const VirtualRefinements = ({ virtualRefinements = {} }: Props) => {
	const { refinementList = {} } = virtualRefinements

	return (
		<>
			{Object.entries(refinementList).map(([attribute, refinements]) => {
				return (
					<VirtualRefinementList
						key={attribute}
						attribute={attribute}
						defaultRefinement={refinements as any}
					/>
				)
			})}
		</>
	)
}

interface Props {
	virtualRefinements?: SearchState
}

export default VirtualRefinements
