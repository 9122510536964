import React, { useContext } from "react"
import { Box, List, ListItem, ListItemText, IconButton, Badge } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"
import Price from "components/Price"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"

import { BiCartAlt } from "react-icons/bi"

const useStyles = makeStyles()(() => ({
	headerIconText: {
		display: "block",
		color: "white",
		fontSize: 16,
		"@media only screen and (max-width: 1280px)": {
			display: "none",
		},
	},
}))

const HelpPopoverDesktop = ({ actualNumberOfLineItems }: Props) => {
	const { classes } = useStyles()
	const { cart } = useContext(AppContext)

	const { lineItems = [] } = cart ?? {}
	const mergedConfiguredLineItems = mergeConfiguredLineItems(lineItems)

	const totalGrossCents = cart?.taxedPrice?.totalGross?.centAmount ?? 0

	return (
		<>
			<div className="cart-dropdown">
				<Link href="/cart">
					<IconButton
						edge="end"
						aria-label="cart"
						aria-haspopup="true"
						color="inherit"
						style={{ marginLeft: -22 }}
						size="large"
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								textAlign: "left",
							}}
						>
							<Badge
								badgeContent={
									actualNumberOfLineItems ? actualNumberOfLineItems : null
								}
								color="primary"
							>
								<BiCartAlt size="1.7rem" color="white" />
							</Badge>
							<Box ml={1} className={classes.headerIconText}>
								Warenkorb
							</Box>
						</div>
					</IconButton>
				</Link>
				<div className="cart-dropdown-content" style={{ textAlign: "left" }}>
					<Box mb={2}>
						<List component="nav" aria-label="help-links">
							{mergedConfiguredLineItems.map((lineItem) => {
								const {
									id,
									quantity,
									taxedPrice: {
										totalGross: { centAmount: lineItemTotalGrossCents },
									},
									//configurations = [],
								} = lineItem

								const name = lineItem.variant?.variantName ?? lineItem.name

								return (
									<ListItem
										key={id}
										style={{ color: "#263739", fontSize: 10 }}
										button
									>
										<ListItemText
											style={{ maxWidth: "75%" }}
											primary={
												<div style={{ fontSize: 12, paddingRight: 5 }}>
													{quantity}x {name}
												</div>
											}
										/>
										<ListItemText
											primary={
												<Price
													priceInEuroCents={lineItemTotalGrossCents}
													style={{ fontSize: 12 }}
												/>
											}
											style={{ textAlign: "right" }}
										/>
									</ListItem>
								)
							})}
						</List>
						{actualNumberOfLineItems !== 0 && (
							<Box
								mb={3}
								px={2}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									fontSize: 14,
								}}
							>
								<div>
									<strong>Gesamt</strong>
								</div>
								<div>
									<strong>
										<Price priceInEuroCents={totalGrossCents} />
									</strong>
								</div>
							</Box>
						)}
						{actualNumberOfLineItems === 0 && (
							<Box mb={3} px={2} style={{ color: "#263739" }}>
								Ihr Warenkorb ist leer.
							</Box>
						)}
						<Box px={1} mt={1}>
							{actualNumberOfLineItems === 0 ? (
								<Link href="/c/einbruchsichere-tresore">
									<Button color="primary" size="small" style={{ width: "100%" }}>
										Jetzt einkaufen
									</Button>
								</Link>
							) : (
								<Link href="/cart">
									<Button color="primary" size="small" style={{ width: "100%" }}>
										Zum Warenkorb
									</Button>
								</Link>
							)}
						</Box>
					</Box>
				</div>
			</div>
		</>
	)
}

interface Props {
	actualNumberOfLineItems: number
}

export default HelpPopoverDesktop
