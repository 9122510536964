import { ParsedUrlQuery } from "querystring"

const fallbackCountryCode = "DE"

export const isValidInstanceCountryCode = (countryCode: string) => {
	return ["AT", "DE"].includes(countryCode)
}

const getCountryCodeFromHostAndQueryParams = (host: string, query: ParsedUrlQuery) => {
	const normalizedCountryCode = ((query?.country as string) ?? "").toUpperCase()
	if (isValidInstanceCountryCode(normalizedCountryCode)) {
		return normalizedCountryCode
	}

	const hasDomainWithTLD = host.includes(".")
	if (hasDomainWithTLD) {
		const [domainCountryCode] = host.split(".").reverse()
		const normalizedDomainCountryCode = domainCountryCode.toUpperCase()

		if (isValidInstanceCountryCode(normalizedDomainCountryCode))
			return normalizedDomainCountryCode
		else return fallbackCountryCode
	}

	return fallbackCountryCode
}

export default getCountryCodeFromHostAndQueryParams
