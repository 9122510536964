import React from "react"
import { withRouter, NextRouter } from "next/router"

import CountryFlag from "./CountryFlag"

import { CountryCode } from "@secureo/common/typings/CountryCode"

interface WithRouterProps {
	router: NextRouter
}

const getLocalizedHost = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "https://www.tresoro.at"
		case "DE":
		default:
			return "https://www.tresoro.de"
	}
}

const SwitchCountry = ({ countryCode, router }: Props) => {
	return (
		<div style={{ display: "flex" }}>
			<a
				href={`${getLocalizedHost("DE")}${router.asPath}`}
				style={{
					marginRight: "10px",
					pointerEvents: countryCode === "DE" ? "none" : "auto",
					opacity: countryCode === "AT" ? 0.65 : 1,
				}}
			>
				<div
					style={{
						width: "20px",
						height: "100%",
						marginTop: 3,
					}}
				>
					<CountryFlag countryCode={"DE"} />
				</div>
			</a>
			<a
				href={`${getLocalizedHost("AT")}${router.asPath}`}
				style={{
					pointerEvents: countryCode === "AT" ? "none" : "auto",
					opacity: countryCode === "AT" ? 1 : 0.65,
				}}
			>
				<div
					style={{
						width: "20px",
						height: "100%",
						marginTop: 3,
					}}
				>
					<CountryFlag countryCode="AT" />
				</div>
			</a>
		</div>
	)
}

interface Props extends WithRouterProps {
	countryCode: CountryCode
}

export default withRouter(SwitchCountry)
