import NextLink, { LinkProps } from "next/link"

const Link = ({ href, children, style, ...props }: Props) => {
	return (
		<NextLink {...props} href={href} style={{ textDecoration: "inherit", ...style }}>
			{children}
		</NextLink>
	)
}

interface Props extends LinkProps {
	href: string
	children: React.ReactNode
	style?: React.CSSProperties
	target?: string
	onClick?: () => void
}

export default Link
