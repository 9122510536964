import { CountryCode } from "@secureo/common/typings/CountryCode"

export const getFallbackMetaTitle = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "Tresore & Safes kaufen: Qualität vom Experten | Tresoro.at"
		default:
			return "Tresore & Safes kaufen: Qualität vom Experten | Tresoro.de"
	}
}

export const getFallbackMetaDescription = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "Wir sind Ihr Experte für Tresore, Safes & Sicherheitstechnik: ✓ 1.500+ Produkte ✓ Renommierte Hersteller ✓ Kostenloser Versand ► Jetzt kaufen!"
		default:
			return "Wir sind Ihr Experte für Tresore, Safes & Sicherheitstechnik: ✓ 1.500+ Produkte ✓ Renommierte Hersteller ✓ Kostenloser Versand ► Jetzt kaufen!"
	}
}
