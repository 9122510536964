import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import formatPrice from "@secureo/common/utils/forex/formatPrice"
import { NextSeoProps } from "next-seo"
import { SEOProps } from "../../../components/SEO"

const getFallbackCategoryMetaTitle = (categoryName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${categoryName} online kaufen | Tresoro.at`
		default:
			return `${categoryName} online kaufen | Tresoro.de`
	}
}

export const getFallbackCategoryMetaDescription = (
	categoryName: string,
	countryCode: CountryCode,
) => {
	switch (countryCode) {
		case "AT":
			return `${categoryName} günstig bei Tresoro.at kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
		default:
			return `${categoryName} günstig bei Tresoro.de kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
	}
}

const getCategoryNextSEOProps = (props: SEOProps) => {
	const { title, description, categoryName, countryCode } = props

	const categorySEOProps: NextSeoProps = {}

	if (title) {
		categorySEOProps.title = title
	} else if (categoryName) {
		categorySEOProps.title = getFallbackCategoryMetaTitle(categoryName, countryCode)
	}
	if (description) {
		categorySEOProps.description = description
	} else if (categoryName) {
		categorySEOProps.description = getFallbackCategoryMetaDescription(categoryName, countryCode)
	}

	return categorySEOProps
}

export default getCategoryNextSEOProps
