import React from "react"
import { Box, ListItem, List, ListItemText, Divider, Paper } from "@mui/material"

import Price from "components/Price"

import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"

import { LineItem, CustomLineItem } from "@secureo/common/typings/Cart"

const getTotalLineItemDiscountsCents = (lineItems: LineItem[]) => {
	const totalLineItemDiscountsCents = lineItems.reduce(
		(totalLineItemDiscountsCents, { totalDiscountGrossCents }) => {
			return totalLineItemDiscountsCents + totalDiscountGrossCents
		},
		0,
	)

	return totalLineItemDiscountsCents
}

const getTotalShippingCosts = (customLineItems: CustomLineItem[]) => {
	const totalShippingCostsEuroCents = customLineItems
		.filter(({ name, slug }) => name.includes("shipping") || slug.includes("delivery"))
		.reduce(
			(
				totalShippingCostsEuroCents,
				{
					taxedPrice: {
						totalGross: { centAmount: totalCentAmountGross },
					},
				},
			) => {
				return totalShippingCostsEuroCents + totalCentAmountGross
			},
			0,
		)

	return totalShippingCostsEuroCents
}

const OrderOverview = ({
	lineItems,
	customLineItems,
	totalGrossCents,
	priceStyle = {},
}: // isOrderSuccessPage = false,
Props) => {
	const mergedConfiguredLineItems = mergeConfiguredLineItems(lineItems)
	const totalShippingCostsEuroCents = getTotalShippingCosts(customLineItems)
	const totalLineItemDiscountsCents = getTotalLineItemDiscountsCents(lineItems)

	return (
		<>
			<Paper
				style={{
					boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.10)",
					paddingBottom: 10,
				}}
			>
				<Box py={2} mx={2} className="h2" style={{ borderBottom: "1px solid #B1BFC3" }}>
					Bestellzusammenfassung
				</Box>
				<List component="nav" aria-label="secondary mailbox folders">
					{mergedConfiguredLineItems.map((lineItem) => {
						const {
							id,
							quantity,
							totalUnDiscountedPriceGrossCents,
							configurations = [],
						} = lineItem

						const name = lineItem.variant?.variantName ?? lineItem.name

						const configurationsTotalUnDiscountedPriceGrossCents =
							configurations.reduce(
								(configurationsTotalUnDiscountedPriceGrossCents, configuration) => {
									return (
										configurationsTotalUnDiscountedPriceGrossCents +
										configuration.totalUnDiscountedPriceGrossCents
									)
								},
								0,
							)

						return (
							<ListItem key={id}>
								<ListItemText
									style={{ maxWidth: "75%" }}
									primary={`${quantity}x ${name}`}
								/>
								<ListItemText
									primary={
										<Price
											priceInEuroCents={
												totalUnDiscountedPriceGrossCents +
												configurationsTotalUnDiscountedPriceGrossCents
											}
											style={priceStyle}
										/>
									}
									style={{ textAlign: "right" }}
								/>
							</ListItem>
						)
					})}
					<Box px={2} py={1}>
						<Divider />
					</Box>

					{totalLineItemDiscountsCents > 0 && (
						<ListItem style={{ display: "flex", justifyContent: "space-between" }}>
							<ListItemText primary={<div>Rabatte</div>} />
							<ListItemText
								primary={
									<div style={{ textAlign: "right" }}>
										<Price
											priceInEuroCents={-totalLineItemDiscountsCents}
											style={priceStyle}
										/>
									</div>
								}
							/>
						</ListItem>
					)}

					{totalShippingCostsEuroCents > 0 && (
						<ListItem style={{ display: "flex", justifyContent: "space-between" }}>
							<ListItemText primary={<div>Versandkosten</div>} />
							<ListItemText
								primary={
									<div style={{ textAlign: "right" }}>
										<Price
											priceInEuroCents={totalShippingCostsEuroCents}
											style={priceStyle}
										/>
									</div>
								}
							/>
						</ListItem>
					)}

					<ListItem>
						<ListItemText
							primary={
								<div>
									<strong>Gesamt</strong>
								</div>
							}
						/>
						<ListItemText
							primary={
								<div>
									<strong>
										<Price
											priceInEuroCents={totalGrossCents}
											style={priceStyle}
										/>
									</strong>
								</div>
							}
							style={{ textAlign: "right" }}
						/>
					</ListItem>
				</List>
				<Box px={2} py={1} mx={1} style={{ background: "#F4F4F8", borderRadius: 5 }}>
					<p style={{ color: "#707070", fontSize: 10 }}>
						*Die oben genannten Preise verstehen sich inkl. Mehrwertsteuer.
					</p>
				</Box>
			</Paper>
		</>
	)
}
interface Props {
	lineItems: LineItem[]
	customLineItems: CustomLineItem[]
	totalGrossCents: number
	priceStyle?: React.CSSProperties
	isOrderSuccessPage?: boolean
}

export default OrderOverview
