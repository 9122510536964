import { NextSeoProps } from "next-seo"
import { SEOProps } from "../../../components/SEO"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const getFallbackProductMetaTitle = (productName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${productName} jetzt günstig & portofrei bei Tresoro.at kaufen`
		default:
			return `${productName} jetzt günstig & portofrei bei Tresoro.de kaufen`
	}
}

const getFallbackProductMetaDescription = (productName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${productName} jetzt bei Tresoro.at kaufen! &#10003; Portofrei &#10003; Kauf auf Rechnung &#10003; Trusted Shop®`
		default:
			return `${productName} jetzt bei Tresoro.de kaufen! &#10003; Portofrei &#10003; Kauf auf Rechnung &#10003; Trusted Shop®`
	}
}

const getProductNextSEOProps = (props: SEOProps, countryCode: CountryCode) => {
	const { title, description, productName } = props

	const productSEOProps: NextSeoProps = {}

	if (title) {
		productSEOProps.title = title
	} else if (productName) {
		productSEOProps.title = getFallbackProductMetaTitle(productName, countryCode)
	}
	if (description) {
		productSEOProps.description = description
	} else if (productName) {
		productSEOProps.description = getFallbackProductMetaDescription(productName, countryCode)
	}

	return productSEOProps
}

export default getProductNextSEOProps
