import { createTheme } from "@mui/material/styles"

const theme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: "#01B2C4",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			// light: will be calculated from palette.primary.main,
			main: "#263739",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		// error: will use the default color
	},
	typography: {
		fontFamily: ["Mulish"].join(","),
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					size: "medium",
					typography: {
						fontFamily: ["Mulish"].join(","),
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: { size: "small" },
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiTable: {
			styleOverrides: {
				root: { size: "medium" },
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: { margin: "dense" },
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: { variant: "dense" },
			},
		},
		// If we want to disable the Ripples -> enable below
		// MuiButtonBase: {
		//   disableRipple: true 💣!
		// }
	},
})

export default theme
