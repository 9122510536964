// CommerceTools
export const NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY =
	process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY
export const COMMERCETOOLS_BACKEND_CLIENT_ID = process.env.COMMERCETOOLS_BACKEND_CLIENT_ID
export const COMMERCETOOLS_BACKEND_CLIENT_SECRET = process.env.COMMERCETOOLS_BACKEND_CLIENT_SECRET

// Algolia
export const NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID
export const NEXT_PUBLIC_ALGOLIA_SEARCH_SEARCH_KEY =
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_SEARCH_KEY

// Mpay24
export const MPAY24_ENV = process.env.MPAY24_ENV || "TEST"
export const MPAY24_MERCHANT_ID = process.env.MPAY24_MERCHANT_ID
export const MPAY24_PASSWORD = process.env.MPAY24_PASSWORD

// Klarna
export const KLARNA_API_ROOT = process.env.KLARNA_API_ROOT
export const KLARNA_API_USERNAME = process.env.KLARNA_API_USERNAME
export const KLARNA_API_PASSWORD = process.env.KLARNA_API_PASSWORD

// SendInBlue
export const SENDINBLUE_API_V3_KEY = process.env.SENDINBLUE_API_V3_KEY

// Google Tag Manager/Analytics
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID =
	process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID

// Misc
export const NEXT_PUBLIC_HOST =
	process.env.NEXT_PUBLIC_HOST ||
	(process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: "http://localhost:3000")

// Trusted Shops
export const NEXT_PUBLIC_ENABLE_TRUSTED_SHOPS =
	process.env.NEXT_PUBLIC_ENABLE_TRUSTED_SHOPS === "true"
