import React, { useState, useEffect, useContext } from "react"
import { Container, Grid, Box, IconButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"

import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"

import { connectSearchBox } from "react-instantsearch-dom"
import { SearchBoxProvided, SearchBoxExposed } from "react-instantsearch-core"

import SearchBoxInput from "./SearchBoxInput"
import SearchBoxResults from "./SearchBoxResults"

import useDebounce from "@secureo/common/hooks/useDebounce"

import searchConfig from "config/searchConfig"

import useWindowSize from "@secureo/common/hooks/useWindowSize"
import AppContext from "@secureo/common/context/AppContext/AppContext"

const useStyles = makeStyles()((theme) => ({
	showAddOns: {
		// "Produkte & Close Button"
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	buttonPosition: {
		maxWidth: 300,
		margin: "auto",
		marginTop: 20,
		"@media only screen and (max-width: 959.58px)": {
			marginTop: 15,
		},
	},
	searchResults: {
		color: theme.palette.secondary.dark,
		position: "absolute",
		zIndex: 9999,
		height: 510,
		minHeight: 510,
		maxHeight: "auto",
		width: "100%",
		left: 0,
		border: "none",
		margin: "0",
		background: "white",
		boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.25)",
		marginTop: "10px",
		overflow: "hidden",
		"@media only screen and (min-width: 960px)": {
			marginTop: "57px",
		},
		"@media only screen and (max-width: 960px)": {
			maxHeight: 300,
			minHeight: 570,
		},
	},
	productResults: {
		height: 380,
		overflowY: "scroll",
		overflowX: "hidden",
		"@media only screen and (max-width: 960px)": {
			minHeight: 480,
		},
	},
	hidden: {
		height: 0,
		display: "none",
	},
}))

const SearchBox = ({ currentRefinement, refine }: SearchBoxProvided) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const { classes } = useStyles()

	const [searchQuery, setSearchQuery] = useState(currentRefinement)
	const debouncedSearchQuery = useDebounce(searchQuery, searchConfig.debounceSearchMs)

	const { dispatch } = useContext(AppContext)
	const { innerWidth } = useWindowSize()
	const isTablet = innerWidth < 960

	const toggleHeader = () => {
		if (isTablet)
			dispatch({
				type: "SEARCHBOX_IS_OPEN",
			})
	}

	useEffect(() => {
		refine(debouncedSearchQuery)
	}, [debouncedSearchQuery, refine])

	const searchQueryHasLength = searchQuery.length > 0

	if (searchQueryHasLength && !isExpanded) setIsExpanded(true)
	if (!searchQueryHasLength && isExpanded) setIsExpanded(false)

	const onSubmit = () => {
		if (searchQuery.length > 0) {
			// This does not work currently on client side transitions + getServerSideProps,
			// because query params are not passed to the lambda function, use hard refresh for now, re-enable later
			// router.push("/search", `/search?query=${searchQuery}`)
			// setSearchQuery("")
			window.location.href = `/search?query=${searchQuery}`
		}
	}

	const closeSearchBar = () => {
		refine("")
		setSearchQuery("")
		toggleHeader()
	}

	return (
		<>
			<SearchBoxInput
				onSubmit={onSubmit}
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				toggleHeader={toggleHeader}
			/>
			{searchQuery.length >= searchConfig.minCharactersBeforeSearch && (
				<div
					className={!isExpanded ? classes.hidden : classes.searchResults}
					style={{ textAlign: "left" }}
				>
					<Box mx="1vw">
						<Container disableGutters style={{ position: "relative" }}>
							<div style={{ margin: "auto" }}>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<Box py={2}>
											<Box pb={1} ml={2} className={classes.showAddOns}>
												<strong style={{ textTransform: "uppercase" }}>
													Produkte
												</strong>
											</Box>
											<Box
												pt={1}
												px={1}
												ml={1}
												className={classes.productResults}
											>
												<SearchBoxResults
													searchQuery={searchQuery}
													closeSearchBar={closeSearchBar}
												/>
											</Box>
											<Box className={classes.buttonPosition}>
												<Button
													color="primary"
													size="small"
													onClick={onSubmit}
													style={{ width: "100%" }}
												>
													Alle Ergebnisse anzeigen
												</Button>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</div>
							<div
								style={{ position: "absolute", right: "0px", top: "5px" }}
								className={classes.showAddOns}
							>
								<IconButton
									aria-label="close search"
									onClick={closeSearchBar}
									size="large"
								>
									<Icon path={mdiClose} size={1} />
								</IconButton>
							</div>
						</Container>
					</Box>
				</div>
			)}
		</>
	)
}

const ExposedSearchBox: React.ComponentClass<SearchBoxExposed> = connectSearchBox(SearchBox)

export default ExposedSearchBox
