import React from "react"
import { Box, Container } from "@mui/material"

import Icon from "@mdi/react"
import { mdiPhone, mdiEmailOutline } from "@mdi/js"

import { Phone, Email } from "components/i18n/Contact"
import { CountryCode } from "@secureo/common/typings/CountryCode"

import SwitchCountry from "components/i18n/SwitchCountry"

const TopBar = ({ countryCode }: Props) => {
	return (
		<Box
			style={{
				background: "#01B2C4",
				color: "white",
				paddingTop: 2,
				paddingBottom: 2,
				fontSize: 13,
			}}
		>
			<Container>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					{/* Left */}
					<div style={{ display: "flex", alignItems: "center" }}>
						<strong>Fragen?</strong>
						<Box ml={1}>Kontaktieren Sie uns</Box>
						<Box mx={1}>
							<Icon path={mdiPhone} size={0.6} style={{ marginTop: 4 }} />
						</Box>
						<div>
							<Phone countryCode={countryCode} />
						</div>
						<Box mx={1}>
							<Icon path={mdiEmailOutline} size={0.6} style={{ marginTop: 4 }} />
						</Box>
						<div>
							<Email countryCode={countryCode} />
						</div>
					</div>
					{/* Right */}
					<div>
						<SwitchCountry countryCode={countryCode} />
					</div>
				</div>
			</Container>
		</Box>
	)
}

interface Props {
	countryCode: CountryCode
}

export default TopBar
