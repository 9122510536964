import { NextSeoProps } from "next-seo"
import { parseUrlParts } from "parse-url-parts"

const getLanguageAlternatesMetaTags = (canonical: string, asPath: string) => {
	// Disable for paginated category pages
	const isPaginatedAsPath = asPath.includes("page=")
	if (isPaginatedAsPath) return []

	try {
		const { protocol, subDomains, path = "" } = parseUrlParts(canonical)

		const languageAlternates: NextSeoProps["languageAlternates"] = [
			{
				hrefLang: "de",
				href: `${protocol}://${subDomains ? subDomains + "." : ""}tresoro.de${path}`,
			},
			{
				hrefLang: "de-DE",
				href: `${protocol}://${subDomains ? subDomains + "." : ""}tresoro.de${path}`,
			},
			{
				hrefLang: "de-AT",
				href: `${protocol}://${subDomains ? subDomains + "." : ""}tresoro.at${path}`,
			},
		]

		return languageAlternates
	} catch (err) {
		console.error("Failed to create languageAlternates from: ", canonical)
		console.error(err)
		return []
	}
}

export default getLanguageAlternatesMetaTags
