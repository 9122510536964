import React from "react"
import Link from "components/i18n/Link"
import { Grid, Box } from "@mui/material"

import { makeStyles } from "makeStyles"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"

import { RiSafe2Line } from "react-icons/ri"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()(() => ({
	subCategory: {
		color: "black",
		textAlign: "left",
		padding: 4,
		paddingLeft: 8,
		fontSize: "0.9rem",
		"&:hover": {
			background: "whitesmoke",
			color: "#01B2C4",
		},
	},
}))

const DesktopNavigation = ({ navCategories, countryCode }: Props) => {
	const { classes } = useStyles()

	return (
		<Grid container>
			<Grid item xs={10}>
				<div style={{ display: "flex" }}>
					{navCategories.map((navCategory) => {
						const { name, children = [], slug, excludedCountryCodes = [] } = navCategory

						if (excludedCountryCodes.includes(countryCode)) return null

						const isBrandCategoryList = slug === "marken"
						return (
							<div key={slug} className="nav-dropdown">
								{isBrandCategoryList ? (
									<Link
										href={`/marken`}
										style={{ width: "100%", color: "white" }}
									>
										<div>{name}</div>
									</Link>
								) : (
									<Link
										href={`/c/${slug}`}
										style={{ width: "100%", color: "white" }}
									>
										<div>{name}</div>
									</Link>
								)}
								<div className="nav-dropdown-content">
									{children.map((child) => {
										const { name, slug, excludedCountryCodes = [] } = child
										if (excludedCountryCodes.includes(countryCode)) return null
										if (isBrandCategoryList)
											return (
												<Link
													key={slug}
													href={`/marken/${slug}`}
													style={{ width: "100%" }}
												>
													<div className={classes.subCategory}>
														{name}
													</div>
												</Link>
											)

										return (
											<Link
												key={slug}
												href={`/c/${slug}`}
												style={{ width: "100%" }}
											>
												<div className={classes.subCategory}>{name}</div>
											</Link>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</Grid>
			<Grid item xs={2}>
				<div style={{ float: "right", padding: "8px 0px 8px 10px" }}>
					<Link href="/tresorberater" style={{ width: "100%", color: "white" }}>
						<div
							style={{ display: "flex", alignItems: "center", alignSelf: "flex-end" }}
						>
							<RiSafe2Line size="1rem" />
							<Box ml={1}>Tresorberater</Box>
						</div>
					</Link>
				</div>
			</Grid>
		</Grid>
	)
}

interface Props {
	navCategories: NavCategory[]
	countryCode: CountryCode
}

export default DesktopNavigation
