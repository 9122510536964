import { SEOProps } from "../../components/SEO"
import { NextSeoProps } from "next-seo"
import { OpenGraphMedia } from "next-seo/lib/types"

const openGraphSiteName = "Tresoro"

const fallbackOpenGraphImages: OpenGraphMedia[] = []

const getOpenGraphLocaleFromLanguage = (language: string) => {
	switch (language) {
		case "de":
			return "de_DE"
		default:
			return "de_DE"
	}
}

const getOpenGraphSEOProps = (props: SEOProps, canonical: string, language = "de") => {
	const { title, description } = props

	const images = props?.openGraph?.images ?? fallbackOpenGraphImages

	const openGraphSEOProps: NextSeoProps = {
		openGraph: {
			url: canonical,
			title: title,
			description: description,
			images,
			site_name: openGraphSiteName,
			locale: getOpenGraphLocaleFromLanguage(language),
		},
	}

	return openGraphSEOProps
}

export default getOpenGraphSEOProps
