import React, { useContext } from "react"
import Link from "components/i18n/Link"
import { Box, Container, Grid } from "@mui/material"

import { makeStyles } from "makeStyles"

import SearchBox from "../Algolia/SearchBox/SearchBox"
import { InstantSearch } from "react-instantsearch-dom"
import algoliaSearchClient from "@secureo/common/utils/algolia/searchClient"
import searchConfig from "config/searchConfig"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { LineItem } from "@secureo/common/typings/Cart"

import LoginPopover from "components/Layout/LoginPopover"
import CartPopover from "components/Layout/CartPopover"
import DesktopNavigation from "components/Layout/DesktopNavigation"
import MobileNavigation from "./MobileNavigation"

import navCategories from "config/navCategories.json"
import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import getLanguageAndCountryCodeFromLocale from "@secureo/common/utils/i18n/getLanguageAndCountryCodeFromLocale"

import Logo from "svg/layout/Logo-light.svg"

import { AiOutlineQuestionCircle } from "react-icons/ai"
import { ShoppingListLineItem } from "@secureo/common/typings/ShoppingList"
import VirtualRefinements from "@secureo/common/components/Algolia/VirtualRefinements"

const useStyles = makeStyles()(() => ({
	desktopSearch: {
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	desktopNavigation: {
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	mobileNavigation: {
		display: "none",
		"@media only screen and (max-width: 960px)": {
			display: "block",
		},
	},
	headerIconText: {
		display: "block",
		color: "white",
		"@media only screen and (max-width: 1280px)": {
			display: "none",
		},
	},
}))

const getNumberOfLineItems = (lineItems: (LineItem | ShoppingListLineItem)[]) => {
	const lineItemsWithoutConfigurations = lineItems.filter(
		({ isConfiguration }) => !isConfiguration,
	)
	const numberOfLineItems = lineItemsWithoutConfigurations.reduce(
		(numberOfLineItems, lineItem) => numberOfLineItems + lineItem.quantity,
		0,
	)

	return numberOfLineItems
}

const searchClient = {
	search(requests: any[]) {
		if (
			requests.every(
				({ params }) =>
					!params.query || params.query.length < searchConfig.minCharactersBeforeSearch,
			)
		) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					processingTimeMS: 0,
				})),
			})
		}

		return algoliaSearchClient.search(requests)
	},
}

const Header = ({ locale }: Props) => {
	const { classes } = useStyles()
	const { cart, shoppingList, showHeader, isInitialized } = useContext(AppContext)

	const numberOfLineItems = getNumberOfLineItems(cart?.lineItems ?? [])
	const actualNumberOfLineItems = numberOfLineItems

	const numberOfShoppingListLineItems = getNumberOfLineItems(shoppingList?.lineItems ?? [])

	const indexName = `TRE-${locale}-products`

	const { countryCode } = getLanguageAndCountryCodeFromLocale(locale)

	return (
		<header>
			<InstantSearch indexName={indexName} searchClient={searchClient}>
				<VirtualRefinements
					virtualRefinements={{
						refinementList: {
							lockType: ["Alle Anzeigen"],
						},
					}}
				/>
				{showHeader && (
					<Box py={1} style={{ background: "#093A3E" }}>
						<Container>
							{/* Header / Search */}
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={2}>
									<Link href="/">
										<Logo style={{ height: 50, paddingTop: 5 }} />
									</Link>
								</Grid>
								<Grid
									item
									xs={false}
									md={6}
									lg={5}
									className={classes.desktopSearch}
								>
									<Box mt={1}>
										<SearchBox />
									</Box>
								</Grid>
								<Grid
									item
									xs={9}
									md={3}
									lg={5}
									style={{
										textAlign: "right",
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										marginTop: 5,
									}}
								>
									<Link href="/beratungscenter">
										<Box
											mr={2}
											style={{
												display: "flex",
												alignItems: "center",
												textAlign: "left",
											}}
										>
											<AiOutlineQuestionCircle size="1.7rem" color="white" />
											<Box ml={1} className={classes.headerIconText}>
												Beratung
											</Box>
										</Box>
									</Link>

									<LoginPopover
										numberOfShoppingListLineItems={
											numberOfShoppingListLineItems
										}
									/>

									{isInitialized && (
										<CartPopover
											actualNumberOfLineItems={actualNumberOfLineItems}
										/>
									)}
								</Grid>
							</Grid>
						</Container>
					</Box>
				)}
				{/* Desktop Navigation */}
				<Box style={{ background: "#093A3E" }} className={classes.desktopNavigation}>
					<Container style={{ color: "white" }}>
						<DesktopNavigation
							navCategories={navCategories as NavCategory[]}
							countryCode={countryCode}
						/>
					</Container>
				</Box>
				{/* Mobile Navigation */}
				<Box py={1} style={{ background: "#093A3E" }} className={classes.mobileNavigation}>
					<Container style={{ color: "white" }}>
						<MobileNavigation
							navCategories={navCategories as NavCategory[]}
							locale={locale}
							countryCode={countryCode}
						/>
					</Container>
				</Box>
			</InstantSearch>
		</header>
	)
}

interface Props {
	locale: string
}

export default Header
