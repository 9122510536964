import React from "react"

import removeWhiteSpace from "@secureo/common/utils/removeWhiteSpace"

import { CountryCode } from "@secureo/common/typings/CountryCode"

// Social Media
export const socialMediaPages = {
	facebook: "https://de-de.facebook.com/secureogmbh/",
	instagram: "https://www.instagram.com/secureogmbh/?hl=de",
	twitter: "https://twitter.com/secureogmbh",
}

// Email
export const emailAddresses = {
	DE: "office@tresoro.de",
	AT: "office@tresoro.at",
}

export const getLocalizedEmail = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return emailAddresses.AT
		case "DE":
		default:
			return emailAddresses.DE
	}
}

// Phone
export const phoneNumbers = {
	DE: "+49 800 5895548",
	AT: "+43 800 640357",
}
export const fallBackPhoneNumber = "+43 512 932791"

export const getLocalizedPhoneNumber = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "DE":
			return phoneNumbers.DE
		case "AT":
			return phoneNumbers.AT
		default:
			return fallBackPhoneNumber
	}
}

export const Phone = ({ countryCode, children }: PhoneProps) => {
	const phoneNumber = getLocalizedPhoneNumber(countryCode)

	return (
		<a href={`tel:${removeWhiteSpace(phoneNumber)}`} className="link" data-hj-whitelist>
			{children || phoneNumber}
		</a>
	)
}

interface PhoneProps {
	countryCode: CountryCode
	children?: React.ReactNode
}

export const Email = ({ countryCode, children }: EmailProps) => {
	const emailAddress = getLocalizedEmail(countryCode)
	return (
		<a href={`mailto:${emailAddress}`} className="link" data-hj-whitelist>
			{children || emailAddress}
		</a>
	)
}

interface EmailProps {
	countryCode: CountryCode
	children?: React.ReactNode
}
