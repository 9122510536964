import React, { useContext } from "react"
import dynamic from "next/dynamic"
import { Container, Box, Grid, Hidden } from "@mui/material"
import { makeStyles } from "makeStyles"

import TopBar from "./TopBar"
import Header from "./Header"
import CheckoutHeader from "./CheckoutHeader"
import Footer from "./Footer"
import AuthenticatedPage from "./AuthenticatedPage"
import AccountDesktopNavigation from "containers/Account/AccountDesktopNavigation"

import BackToTop from "components/BackToTop"
import CookieBanner from "components/CookieBanner"

const LoginRegister = dynamic(() => import("../Login/LoginRegister"), { ssr: false })

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()(() => ({
	topBarDisplay: {
		display: "block",
		"@media only screen and (max-width: 700px)": {
			display: "none",
		},
	},
}))

const Layout = ({ children, asPath, locale, countryCode }: Props) => {
	const { classes } = useStyles()
	const { loginRegisterFormIsOpen, openLoginRegisterFormTab, dispatch } = useContext(AppContext)

	const isAccount = asPath?.includes("account") ?? false
	const isCheckout = asPath?.includes("checkout") ?? false

	return (
		<>
			<div className={classes.topBarDisplay}>
				<TopBar countryCode={countryCode} />
			</div>
			<header>{!isCheckout ? <Header locale={locale} /> : <CheckoutHeader />}</header>
			<main
				style={{
					minHeight: "55vh",
				}}
			>
				{!isAccount ? (
					<>{children}</>
				) : (
					<AuthenticatedPage>
						<Container>
							<Box mt={2} mb={5}>
								<Grid container spacing={3}>
									<Hidden mdDown>
										<Grid item xs={12} md={3}>
											<AccountDesktopNavigation countryCode={countryCode} />
										</Grid>
									</Hidden>
									<Grid item xs={12} md={9}>
										{children}
									</Grid>
								</Grid>
							</Box>
						</Container>
					</AuthenticatedPage>
				)}
			</main>
			<BackToTop />
			<CookieBanner />
			<footer>
				<Footer countryCode={countryCode} />
			</footer>
			{loginRegisterFormIsOpen && (
				<LoginRegister
					loginRegisterFormIsOpen={loginRegisterFormIsOpen}
					openLoginRegisterFormTab={openLoginRegisterFormTab}
					dispatch={dispatch}
					locale={locale}
				/>
			)}
		</>
	)
}

interface Props {
	children: React.ReactNode
	asPath: string
	locale: string
	countryCode: CountryCode
}

export default Layout
