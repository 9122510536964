import React from "react"

import JSONLD from "./JSONLD"

const WebsiteJsonLD = () => {
	const json = {
		"@context": "https://schema.org",
		"@type": "WebSite",
		url: `${process.env.NEXT_PUBLIC_HOST}/`,
		potentialAction: {
			"@type": "SearchAction",
			target: {
				"@type": "EntryPoint",
				urlTemplate: `${process.env.NEXT_PUBLIC_HOST}/search?query={search_term_string}`,
			},
			"query-input": {
				"@type": "PropertyValueSpecification",
				valueRequired: "https://schema.org/True",
				valueName: "search_term_string",
			},
		},
	}

	return <JSONLD metaKey="jsonld-website" json={json} />
}

const OrganizationJsonLD = ({ phoneNumber }: OrganizationJsonLDJsonLDProps) => {
	const json = {
		"@context": "https://schema.org",
		"@type": "Organization",
		url: `${process.env.NEXT_PUBLIC_HOST}/`,
		name: "Tresoro",
		logo: `${process.env.NEXT_PUBLIC_HOST}/android-chrome-512x512.png`,
		// TODO: Add social media links
		sameAs: [
			"https://de-de.facebook.com/secureogmbh/",
			"https://twitter.com/secureogmbh",
			"https://www.instagram.com/secureogmbh/?hl=de",
		],
		contactPoint: [
			{
				"@type": "ContactPoint",
				telephone: phoneNumber,
				contactType: "customer service",
			},
		],
	}

	return <JSONLD metaKey="jsonld-organization" json={json} />
}

interface OrganizationJsonLDJsonLDProps {
	phoneNumber: string
}

const GeneralStructuredData = ({ phoneNumber }: Props) => {
	return (
		<>
			<WebsiteJsonLD />
			<OrganizationJsonLD phoneNumber={phoneNumber} />
		</>
	)
}

interface Props {
	phoneNumber: string
}

export default GeneralStructuredData
