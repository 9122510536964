import React, { useContext } from "react"
import { Box, List, ListItem, ListItemText, IconButton, Divider } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import { CgProfile } from "react-icons/cg"

const useStyles = makeStyles()(() => ({
	headerIconText: {
		display: "block",
		color: "white",
		fontSize: 16,
		"@media only screen and (max-width: 1280px)": {
			display: "none",
		},
	},
}))

const LoginPopover = ({ numberOfShoppingListLineItems }: Props) => {
	const { classes } = useStyles()
	const { dispatch, isLoggedIn, customer } = useContext(AppContext)

	const openLoginForm = () => {
		dispatch({
			type: "OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "login",
		})
	}

	const toggleLoginRegisterFormIsOpen = () => {
		dispatch({
			type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN",
		})
	}

	const openRegisterForm = () => {
		dispatch({
			type: "SET_OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "register",
		})
		toggleLoginRegisterFormIsOpen()
	}

	return (
		<>
			<div className="cart-dropdown">
				{isLoggedIn ? (
					<Link href="/account">
						<div style={{ position: "relative" }}>
							<div
								style={{
									height: 13,
									width: 13,
									borderRadius: 13,
									background: "#2FA276",
									position: "absolute",
									top: 7,
									left: 2,
									zIndex: 2,
								}}
							/>
							<IconButton
								edge="end"
								aria-label="cart"
								aria-haspopup="true"
								color="inherit"
								style={{ marginLeft: -5 }}
								size="large"
							>
								<Box
									mr={2}
									style={{
										display: "flex",
										alignItems: "center",
										textAlign: "left",
									}}
								>
									<CgProfile size="1.7rem" color="white" />
									<Box ml={1} className={classes.headerIconText}>
										Konto
									</Box>
								</Box>
							</IconButton>
						</div>
					</Link>
				) : (
					<IconButton
						edge="end"
						aria-label="cart"
						aria-haspopup="true"
						color="inherit"
						style={{ marginLeft: -17, marginRight: 5 }}
						onClick={openLoginForm}
						size="large"
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								textAlign: "left",
							}}
						>
							<CgProfile size="1.7rem" color="white" />
							<Box ml={1} className={classes.headerIconText}>
								Anmelden
							</Box>
						</div>
					</IconButton>
				)}
				<div className="cart-dropdown-content" style={{ textAlign: "left" }}>
					<Box mb={0}>
						{!isLoggedIn && (
							<Box px={1} mt={1}>
								<Button
									color="primary"
									size="small"
									style={{ width: "100%" }}
									onClick={openLoginForm}
								>
									Anmelden
								</Button>
							</Box>
						)}
						<List component="nav" aria-label="help-links">
							{!isLoggedIn && (
								<>
									<ListItem
										style={{ color: "#263739", fontSize: 10 }}
										button
										onClick={openRegisterForm}
									>
										<ListItemText primary="Neuer Kunde? Jetzt registrieren" />
									</ListItem>
								</>
							)}
							{isLoggedIn && (
								<>
									<Box
										px={2}
										mt={1}
										style={{ display: "flex", alignItems: "center" }}
									>
										<div
											style={{
												background: "#009FB8",
												borderRadius: "50%",
												height: "1.3rem",
												width: "1.3rem",
												padding: 5,
												marginTop: -3,
											}}
										>
											<CgProfile size="1.3rem" color="white" />
										</div>
										<Box ml={1} mb={1} style={{ fontSize: 14 }}>
											<div>
												<strong>
													{customer?.firstName} {customer?.lastName}
												</strong>
											</div>
											<div style={{ color: "#B1BFC3", marginTop: -5 }}>
												{customer?.email}
											</div>
										</Box>
									</Box>
									<Divider />
									<Link href="/account">
										<ListItem style={{ color: "#263739", fontSize: 10 }} button>
											<ListItemText primary="Mein Konto" />
										</ListItem>
									</Link>
									<Link href="/account/orders">
										<ListItem style={{ color: "#263739", fontSize: 10 }} button>
											<ListItemText primary="Meine Bestellungen" />
										</ListItem>
									</Link>
									<Link href="/shopping-list">
										<ListItem style={{ color: "#263739", fontSize: 10 }} button>
											<ListItemText
												primary={
													<div>
														Wunschliste{" "}
														{numberOfShoppingListLineItems > 0 &&
															`(
                                                    
                                                        ${numberOfShoppingListLineItems}})`}
													</div>
												}
											/>
										</ListItem>
									</Link>
									<Link href="/logout">
										<ListItem style={{ color: "#263739", fontSize: 10 }} button>
											<ListItemText primary="Abmelden" />
										</ListItem>
									</Link>
								</>
							)}
						</List>
					</Box>
				</div>
			</div>
		</>
	)
}

interface Props {
	numberOfShoppingListLineItems: number
}

export default LoginPopover
