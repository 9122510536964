import React, { useContext } from "react"
import { Box, Button } from "@mui/material"

import Icon from "@mdi/react"
import { mdiMenu, mdiClose } from "@mdi/js"

import useToggle from "@secureo/common/hooks/useToggle"

import SearchBox from "../Algolia/SearchBox/SearchBox"
import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import { CountryCode } from "@secureo/common/typings/CountryCode"

import NavCategoryList from "./NavCategoryList"

import useWindowSize from "@secureo/common/hooks/useWindowSize"
import AppContext from "@secureo/common/context/AppContext/AppContext"

const MobileNavigation = ({ locale, navCategories, countryCode }: Props) => {
	const [isOpen, toggleIsOpen] = useToggle()
	const { dispatch, showHeader } = useContext(AppContext)

	const { innerWidth } = useWindowSize()
	const isTablet = innerWidth < 960

	const toggleHeader = () => {
		if (isTablet)
			dispatch({
				type: "SEARCHBOX_IS_OPEN",
			})
	}

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{showHeader ? (
				<Button
					onClick={toggleIsOpen}
					style={{
						padding: 6,
						width: 20,
						marginLeft: -10,
						marginTop: -5,
					}}
				>
					<Icon
						path={isOpen ? mdiClose : mdiMenu}
						size={1.6}
						color="white"
						style={{
							padding: 0,
							marginTop: -6,
							marginBottom: -10,
						}}
					/>
				</Button>
			) : (
				<Button
					onClick={toggleHeader}
					style={{
						display: "flex",
						textAlign: "left",
						padding: 6,
						width: 20,
						marginLeft: -10,
					}}
				>
					<Icon
						path={mdiClose}
						size={1.6}
						color="white"
						style={{
							padding: 0,
							marginTop: -6,
							marginBottom: -10,
						}}
					/>
				</Button>
			)}
			{isOpen && (
				<NavCategoryList
					categories={navCategories}
					isOpen={isOpen}
					isRootCategory
					onClose={toggleIsOpen}
					countryCode={countryCode}
				/>
			)}
			<Box ml={1} style={{ marginTop: -2, width: "100%" }}>
				<SearchBox />
			</Box>
		</div>
	)
}

interface Props {
	locale: string
	navCategories: NavCategory[]
	countryCode: CountryCode
}

export default MobileNavigation
