import React from "react"
import { Button as MaterialUIButton } from "@mui/material"

import { makeStyles } from "makeStyles"

type ButtonColor =
	| "default"
	| "green"
	| "primary"
	| "primaryOutlined"
	| "secondary"
	| "secondaryOutlined"
	| "link"
	| "link-no-underline"
	| "danger"

const useStyles = makeStyles()((theme) => ({
	// Lightgrey
	default: {
		background: "#EDEFF0 !important",
		color: "#505E5F !important",
		textTransform: "inherit",
		paddingLeft: 20,
		paddingRight: 20,
		border: "none !important",
		"&:hover": {
			background: "#EDEFF0 !important",
			color: theme.palette?.secondary?.dark,
			border: "none !important",
		},
	},
	// green
	green: {
		background: "#29A374 !important",
		color: "#F7F9FA !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #29A374 !important",
		"&:hover": {
			background: "#238C64 !important",
			color: "white !important",
			border: "1px solid #238C64 !important",
		},
	},
	// Blue
	primary: {
		background: "#01B2C4 !important",
		color: "#F7F9FA !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #01B2C4 !important",
		"&:hover": {
			background: "rgba(70, 145, 206, 0.07) !important",
			color: "#01B2C4 !important",
			border: "1px solid #01B2C4 !important",
		},
	},
	// Blue Outlined
	primaryOutlined: {
		background: "rgba(70, 145, 206, 0.07) !important",
		color: "#01B2C4 !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #01B2C4 !important",
		"&:hover": {
			background: "#01B2C4 !important",
			border: "1px solid #01B2C4 !important",
			color: "#F7F9FA !important",
		},
	},
	// Black
	secondary: {
		background: "#263739 !important",
		color: "#F7F9FA !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #263739 !important",
		"&:hover": {
			background: "rgba(70, 145, 206, 0.07) !important",
			color: "#263739 !important",
			border: "1px solid #263739 !important",
		},
	},
	// Black Outlined
	secondaryOutlined: {
		background: "rgba(70, 145, 206, 0.07) !important",
		color: "#263739 !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #263739 !important",
		"&:hover": {
			background: "#263739 !important",
			color: "#F7F9FA !important",
			border: "1px solid #263739 !important",
		},
	},
	// Danger
	danger: {
		background: "#C3423F !important",
		color: "#F7F9FA !important",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "1px solid #C3423F",
		"&:hover": {
			background: "rgba(70, 145, 206, 0.07) !important",
			color: "#C3423F !important",
			border: "1px solid #C3423F !important",
		},
	},

	// link
	link: {
		background: "none",
		color: "#01B2C4",
		textTransform: "inherit",
		textAlign: "left",
		textDecoration: "underline",
		textUnderlinePosition: "under",
		"&:hover": {
			background: "none",
			textDecoration: "underline",
		},
	},
	// link-no-underline
	linkNoUnderline: {
		background: "none",
		color: "black",
		textTransform: "inherit",
		textAlign: "left",
		textDecoration: "none",
		padding: 0,
		"&:hover": {
			background: "none",
			textDecoration: "none",
		},
	},
}))

const getButtonClassName = (
	classes: {
		default: string
		green: string
		primary: string
		primaryOutlined: string
		secondary: string
		secondaryOutlined: string
		danger: string
		link: string
		linkNoUnderline: string
	},
	color: ButtonColor,
) => {
	switch (color) {
		case "green":
			return classes.green
		case "primary":
			return classes.primary
		case "primaryOutlined":
			return classes.primaryOutlined
		case "secondary":
			return classes.secondary
		case "secondaryOutlined":
			return classes.secondaryOutlined
		case "danger":
			return classes.danger
		case "link":
			return classes.link
		case "link-no-underline":
			return classes.linkNoUnderline
		default:
			return classes.default
	}
}
const Button = ({ color, type = "button", size, children, style, ...props }: Props) => {
	const { classes } = useStyles()
	const className = getButtonClassName(classes, color)

	return (
		<MaterialUIButton
			className={className}
			type={type}
			{...props}
			size={size}
			style={{
				borderRadius: 3,
				fontSize: 16,
				fontFamily: "Mulish, sans-serif",
				boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.35)",
				...style,
			}}
		>
			{children}
		</MaterialUIButton>
	)
}

// TODO: types
interface Props {
	children: React.ReactNode
	color?: ButtonColor
	onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	style?: React.CSSProperties
	type?: "button" | "submit" | "reset"
	disabled?: boolean
	disableRipple?: boolean
	tabIndex?: number
	className?: string
	size?: "small" | "medium" | "large"
}

export default Button
