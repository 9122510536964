import React, { useContext } from "react"
import Link from "components/i18n/Link"
import { Box, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import {
	mdiAccountCircle,
	mdiViewDashboard,
	mdiCardAccountDetails,
	mdiLogoutVariant,
	mdiLock,
	mdiCartArrowRight,
	mdiPhone,
	mdiEmailOutline,
} from "@mdi/js"

import { Phone, Email } from "components/i18n/Contact"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const listItems = [
	{
		icon: mdiViewDashboard,
		title: "Übersicht",
		link: "/account",
	},
	{
		icon: mdiCardAccountDetails,
		title: "Adressen",
		link: "/account/addresses",
	},
	{
		icon: mdiLock,
		title: "Anmeldesicherheit",
		link: "/account/security",
	},
	{
		icon: mdiCartArrowRight,
		title: "Bestellungen",
		link: "/account/orders",
	},
	{
		icon: mdiLogoutVariant,
		title: "Logout",
		link: "/logout",
	},
]

const useStyles = makeStyles()(() => ({
	showDesktopAccountNavigation: {
		display: "block",
	},
	"@media only screen and (max-width: 960px)": {
		showDesktopAccountNavigation: {
			display: "none",
		},
	},
}))

const Navigation = ({ countryCode }: Props) => {
	const { classes } = useStyles()
	const { customer } = useContext(AppContext)

	return (
		<Box className={classes.showDesktopAccountNavigation}>
			<h1
				style={{
					display: "flex",
					alignItems: "center",
					marginBottom: 10,
					fontSize: 20,
				}}
			>
				<Icon path={mdiAccountCircle} size={1.2} color="#4691CE" />
				<Box ml={1}>Hallo {customer?.firstName}!</Box>
			</h1>
			<Divider />
			<List component="nav" aria-label="main mailbox folders">
				{listItems.map((listItem) => {
					const { icon, title, link } = listItem
					return (
						<Link href={link} key={title}>
							<ListItem button style={{ paddingLeft: 0, color: "#263739" }}>
								<ListItemIcon>
									<Icon path={icon} size={1} color="#4691CE" />
								</ListItemIcon>
								<ListItemText
									primary={
										<Box ml={-1} style={{ fontSize: "1rem" }}>
											{title}
										</Box>
									}
								/>
							</ListItem>
						</Link>
					)
				})}
			</List>
			<Divider />
			<Box
				mt={2}
				mb={1}
				style={{ textTransform: "uppercase", color: "#4691CE" }}
				className="h3"
			>
				Haben Sie Fragen?
			</Box>
			<p>
				Sie erreichen uns von Montag bis Freitag von 9-16 Uhr oder rund um die Uhr über
				unsere kostenlose Hotline mit Rückrufservice.
			</p>
			<Box my={2}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon path={mdiPhone} size={0.9} color="#4691CE" />
					<Box ml={1}>
						<Phone countryCode={countryCode} />
					</Box>
				</div>
				<br />
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon path={mdiEmailOutline} size={0.9} color="#4691CE" />
					<Box ml={1}>
						<Email countryCode={countryCode} />
					</Box>
				</div>
			</Box>
			<p>
				Außerdem stehen wir Ihnen jeder Zeit per Mail oder über unser{" "}
				<Link href="/kontakt">Kontaktformular</Link> zur Verfügung.
			</p>
		</Box>
	)
}

interface Props {
	countryCode: CountryCode
}

export default Navigation
