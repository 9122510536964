import React, { useContext } from "react"
import { Box, Container } from "@mui/material"
import { makeStyles } from "makeStyles"
import Price from "components/Price"
import Link from "components/i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import CheckoutContext from "@secureo/common/context/CheckoutContext/CheckoutContext"

import Logo from "svg/layout/Logo-light.svg"

import { blurredStyle } from "containers/CheckoutPage/OrderOverviewAndCheckout"

import { LineItem } from "@secureo/common/typings/Cart"

const useStyles = makeStyles()(() => ({
	logo: {
		maxHeight: 60,
		minHeight: "100%",
		maxWidth: "190px",
		height: "60px",
		objectFit: "contain",
	},
	centerH1: {
		display: "block",
		"@media only screen and (max-width: 650px)": {
			display: "none",
		},
	},
	"@media only screen and (max-width: 960px)": {
		logo: {
			maxHeight: 50,
			minHeight: "100%",
			maxWidht: "90px",
			height: "50px",
			objectFit: "contain",
		},
	},
}))

const getNumberOfLineItems = (lineItems: LineItem[]) => {
	const lineItemsWithoutConfigurations = lineItems.filter(
		({ isConfiguration }) => !isConfiguration,
	)
	const numberOfLineItems = lineItemsWithoutConfigurations.reduce(
		(numberOfLineItems, lineItem) => numberOfLineItems + lineItem.quantity,
		0,
	)

	return numberOfLineItems
}

const CheckoutHeader = () => {
	const { classes } = useStyles()
	const { cart } = useContext(AppContext)
	const { isLoading } = useContext(CheckoutContext)

	const priceStyle = isLoading ? blurredStyle : {}

	const numberOfLockingSystems = cart?.lockingSystems?.length
	const numberOfLineItems = getNumberOfLineItems(cart?.lineItems ?? [])

	return (
		<Box py={1} style={{ background: "#093A3E", color: "white" }}>
			<Container
				style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
			>
				<Link href="/">
					<Logo className={classes.logo} />
				</Link>
				<h1 style={{ fontSize: 26, margin: 0 }} className={classes.centerH1}>
					Ihre Bestellung
				</h1>
				<div style={{ fontStyle: "italic" }}>
					{numberOfLineItems + numberOfLockingSystems} Artikel |{" "}
					<Price
						priceInEuroCents={cart?.taxedPrice?.totalGross?.centAmount}
						style={priceStyle}
					/>
				</div>
			</Container>
		</Box>
	)
}

export default CheckoutHeader
