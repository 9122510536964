import React, { useEffect, useState } from "react"
import { AppContext, AppProps } from "next/app"
import { I18nextProvider } from "react-i18next"
import { SnackbarProvider } from "notistack"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"

import { ThemeProvider } from "@mui/material/styles"
import theme from "theme"

import { PageTransitionSpinner } from "components/Spinner"
import Layout from "components/Layout"
import { DefaultSEO } from "components/SEO"
import GeneralStructuredData from "components/StructuredData/GeneralStructuredData"

import { AppContextProvider } from "@secureo/common/context/AppContext/AppContext"
import AppContextController from "@secureo/common/context/AppContext/AppContextController"
import { CheckoutContextProvider } from "@secureo/common/context/CheckoutContext/CheckoutContext"

import useRouteChange from "@secureo/common/hooks/useRouteChange"
import { pushEventToDataLayer } from "@secureo/common/components/GoogleTagManager"

import { CountryCode } from "@secureo/common/typings/CountryCode"

import i18nInstance from "@secureo/common/config/i18n-tresoro"
import isProduction from "@secureo/common/utils/isProduction"

import "styles/global.scss"
import "styles/slider.scss"
import "styles/cart-dropdown.scss"

import getGeoCountry from "@secureo/common/utils/getGeoCountry"
import { paymentMethods } from "config/paymentMethods"
import getCountryCodeFromHostAndQueryParams from "utils/getCountryCodeFromHostAndQueryParams"

// Phone
export const phoneNumbers = {
	DE: "+49 800 5895548",
	AT: "+43 800 640357",
}
export const fallBackPhoneNumber = "+43 512 932791"

export const getLocalizedPhoneNumber = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "DE":
			return phoneNumbers.DE
		case "AT":
			return phoneNumbers.AT
		default:
			return fallBackPhoneNumber
	}
}

const ContextProviders = ({ asPath, countryCode, children }: ContextProvidersProps) => {
	return (
		<AppContextProvider countryCode={countryCode} language="de">
			<AppContextController asPath={asPath} shopCountryCode={countryCode}>
				<CheckoutContextProvider
					shopCountryCode={countryCode}
					paymentMethods={paymentMethods}
				>
					<I18nextProvider i18n={i18nInstance}>
						<SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
					</I18nextProvider>
				</CheckoutContextProvider>
			</AppContextController>
		</AppContextProvider>
	)
}

interface ContextProvidersProps {
	asPath: string
	countryCode: CountryCode
	children: React.ReactNode
}

const CustomApp = (props: Props) => {
	const { Component, pageProps, err } = props

	// eslint-disable-next-line
	const { asPath, locale, countryCode } = pageProps

	const phoneNumber = getLocalizedPhoneNumber(countryCode)

	// Workaround for https://github.com/zeit/next.js/issues/8592
	const modifiedPageProps = { ...pageProps, err } as any
	const isChangingRoute = useRouteChange()

	const [, setGetGeoCountry] = useState(null as CountryCode)

	useEffect(() => {
		getGeoCountry()
			.then(setGetGeoCountry)
			.then(() => {
				console.info(`Country: ${countryCode} `)
			})
	}, [countryCode])

	return (
		<AppCacheProvider {...props}>
			<ThemeProvider theme={theme}>
				<ContextProviders asPath={asPath} countryCode={countryCode}>
					<Layout asPath={asPath} locale={locale} countryCode={countryCode}>
						<DefaultSEO asPath={asPath} countryCode={countryCode} />
						<PageTransitionSpinner isHidden={isChangingRoute}>
							<Component {...modifiedPageProps} />
						</PageTransitionSpinner>
						<GeneralStructuredData phoneNumber={phoneNumber} />
					</Layout>
				</ContextProviders>
			</ThemeProvider>
		</AppCacheProvider>
	)
}

CustomApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
	const { asPath, pathname, req, query } = ctx
	let pageProps: any = {}

	if (Component.getInitialProps) {
		pageProps = await Component.getInitialProps(ctx)
	}

	const host = req?.headers?.host ?? window?.location?.host
	const countryCode = getCountryCodeFromHostAndQueryParams(host, query) as CountryCode
	const locale = `de-${countryCode}`

	const customAppProps: CustomAppProps = {
		// Path information
		asPath,
		pathname,

		// i18n
		locale,
		countryCode,
	}

	return {
		pageProps: {
			...pageProps,
			...customAppProps,
		},
	}
}

export interface CustomAppProps {
	// Errors
	err?: any

	// Path information
	asPath: string
	pathname: string

	// i18n
	locale: string
	countryCode: CountryCode
}

interface Props extends AppProps {
	pageProps: CustomAppProps
	err?: any
}

export function reportWebVitals({ id, name, label, value }) {
	// Only report web vitals in production
	if (isProduction()) {
		pushEventToDataLayer({
			event: "web-vitals",
			event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
			event_action: name,
			// Google Analytics metrics must be integers, so the value is rounded.
			// For CLS the value is first multiplied by 1000 for greater precision
			// (note: increase the multiplier for greater precision if needed).
			event_value: Math.round(name === "CLS" ? value * 1000 : value),
			// The `id` value will be unique to the current page load. When sending
			// multiple values from the same page (e.g. for CLS), Google Analytics can
			// compute a total by grouping on this ID (note: requires `eventLabel` to
			// be a dimension in your report).
			event_label: id,
		})
	}
}

export default CustomApp
