import React from "react"
import Link from "components/i18n/Link"
import { Container, Grid } from "@mui/material"
import { Phone, Email, socialMediaPages } from "components/i18n/Contact"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import SwitchCountry from "components/i18n/SwitchCountry"

import { FaFacebook } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"

import Logo from "svg/layout/Logo-light.svg"
// import Idealo from "svg/layout/idealo.svg"
import LionsHome from "svg/layout/lions-home.svg"

import Vorkasse from "svg/paymentMethods/vorkasse.svg"
import Klarna from "svg/paymentMethods/klarna.svg"
import Eps from "svg/paymentMethods/eps.svg"
import PayPal from "svg/paymentMethods/paypal.svg"
import Visa from "svg/paymentMethods/visa.svg"
import Mastercard from "svg/paymentMethods/mastercard.svg"
import TrustedShopsExellentShopAward from "images/TA-Excellent-Shop-5-Jahre-2023_TA-Excellent-Shop-5-Jahre-2023px.png"
import Image from "next/image"

const consultation = [
	{
		title: "Marken",
		href: "/marken",
	},
	{
		title: "Waffenschränke",
		href: "/c/waffenschraenke",
	},
	{
		title: "Wertschutzschränke",
		href: "/c/wertschutzschraenke",
	},
	{
		title: "Möbeltresore",
		href: "/c/moebeltresore",
	},
	{
		title: "Dokumententresore",
		href: "/c/dokumententresore",
	},
	{
		title: "Waffenschrank Fingerprint",
		href: "/c/waffenschraenke?page=1&menu%5BlockType%5D=Fingerprintschloss",
	},
]

const infoLinks = [
	{
		title: "Kontakt",
		href: "/kontakt",
	},
	{
		title: "Impressum",
		href: "/content/impressum",
	},
	{
		title: "Datenschutz",
		href: "/content/datenschutz",
	},
	{
		title: "AGB",
		href: "/content/agb",
	},
	{
		title: "Widerrufsbelehrung",
		href: "/content/widerrufsbelehrung",
	},
	{
		title: "Lieferung",
		href: "/lieferung-montage",
	},
	{
		title: "Warum mit uns?",
		href: "/content/warum-mit-uns",
	},
	{
		title: "Beratungscenter",
		href: "/beratungscenter",
	},
	{
		title: "Jungjäger Rabatt",
		href: "/waffenschrank-jungjaeger-rabatt",
	},
]

const oftenSearched = [
	{
		title: "Schlüssel zum Waffenschrank aufbewahren",
		href: "/content/aufbewahrung-schluessel-waffenschrank",
	},
	{
		title: "Tresor Sicherheitsstufen",
		href: "/tresor-sicherheitsstufen",
	},
	{
		title: "DSGVO Tresor",
		href: "/content/dsgvo-tresor",
	},
	{
		title: "Tresor im Internet bestellen",
		href: "/content/tresor-im-internet-bestellen",
	},
	{
		title: "Schlüsseltresor für Autohaus",
		href: "/content/schluesseltresor-fuer-autohaus",
	},
	{
		title: "Tresore mit Fingerprint",
		href: "/c/einbruchsichere-tresore?page=1&menu%5BlockType%5D=Fingerprintschloss",
	},
	{
		title: "Tresore für zu Hause",
		href: "/c/wertschutzschraenke?range%5BgrossPriceCents%5D%5Bmax%5D=150000&page=1",
	},
	{
		title: "Warum einen Tresor kaufen",
		href: "/content/warum-einen-tresor-kaufen",
	},
	{
		title: "Tresor Notöffnung",
		href: "/tresor-notoeffnung",
	},
	{
		title: "Tresor Service",
		href: "/tresor-service",
	},
]

const Footer = ({ countryCode }: Props) => {
	return (
		<div style={{ background: "#093A3E", color: "white", paddingTop: 40, paddingBottom: 20 }}>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={3}>
						<div>
							<Link href="/">
								<Logo style={{ maxHeight: 70 }} />
							</Link>
						</div>
						<div
							style={{
								marginTop: 10,
								marginBottom: -1,
								textTransform: "uppercase",
								color: "rgba(255,255,255,0.65)",
							}}
						>
							Kostenlose Hotline
						</div>
						<div>
							<Phone countryCode={countryCode} />
						</div>
						<div
							style={{
								marginTop: 10,
								marginBottom: -1,
								textTransform: "uppercase",
								color: "rgba(255,255,255,0.65)",
							}}
						>
							E-Mail
						</div>
						<div>
							<strong>
								<Email countryCode={countryCode} />
							</strong>
						</div>
						<div
							style={{
								marginTop: 10,
								marginBottom: 5,
								textTransform: "uppercase",
								color: "rgba(255,255,255,0.65)",
							}}
						>
							Social Media
						</div>
						<div>
							<a href={socialMediaPages.facebook} target="_blank" rel="noreferrer">
								<FaFacebook
									size="1.2rem"
									color="white"
									style={{ marginRight: 7 }}
								/>
							</a>{" "}
							<a href={socialMediaPages.twitter} target="_blank" rel="noreferrer">
								<FaTwitter size="1.2rem" color="white" style={{ marginRight: 7 }} />
							</a>{" "}
							<a href={socialMediaPages.instagram} target="_blank" rel="noreferrer">
								<FaInstagram
									size="1.2rem"
									color="white"
									style={{ marginRight: 7 }}
								/>
							</a>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div
							style={{ textTransform: "uppercase", color: "rgba(255,255,255,0.65)" }}
						>
							Info
						</div>
						<ul style={{ listStyle: "none", paddingLeft: 0 }}>
							{infoLinks.map((infoItem) => {
								return (
									<Link
										href={infoItem.href}
										key={infoItem.title}
										style={{ color: "white" }}
									>
										<li key={infoItem.title} style={{ padding: 2 }}>
											{infoItem.title}
										</li>
									</Link>
								)
							})}
						</ul>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div
							style={{ textTransform: "uppercase", color: "rgba(255,255,255,0.65)" }}
						>
							Top Kategorien
						</div>
						<ul style={{ listStyle: "none", paddingLeft: 0 }}>
							{consultation.map((consultationItem) => {
								return (
									<Link
										href={consultationItem.href}
										key={consultationItem.title}
										style={{ color: "white" }}
									>
										<li key={consultationItem.title} style={{ padding: 2 }}>
											{consultationItem.title}
										</li>
									</Link>
								)
							})}
						</ul>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<div
							style={{ textTransform: "uppercase", color: "rgba(255,255,255,0.65)" }}
						>
							Oft gesucht
						</div>
						<ul style={{ listStyle: "none", paddingLeft: 0 }}>
							{oftenSearched.map((oftenSearchedItem) => {
								return (
									<Link
										href={oftenSearchedItem.href}
										key={oftenSearchedItem.title}
										style={{ color: "white" }}
									>
										<li key={oftenSearchedItem.title} style={{ padding: 2 }}>
											{oftenSearchedItem.title}
										</li>
									</Link>
								)
							})}
						</ul>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: 25 }}>
					<Grid item xs={12} sm={6} md={3}>
						<div style={{ marginTop: 20 }}>
							<SwitchCountry countryCode={countryCode} />
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<div style={{ marginBottom: 10 }}>
							<div
								style={{
									textTransform: "uppercase",
									color: "rgba(255,255,255,0.65)",
								}}
							>
								Zahlung
							</div>
						</div>
						<Link href="/content/bezahlung">
							<div style={{ display: "flex", flexFlow: "wrap" }}>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<Vorkasse />
								</svg>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<Eps />
								</svg>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<Klarna />
								</svg>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<PayPal />
								</svg>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<Visa />
								</svg>
								<svg
									style={{
										background: "white",
										width: 70,
										padding: 3,
										paddingTop: 8,
										borderRadius: 5,
										marginRight: 5,
										marginBottom: 5,
									}}
									width={70}
									height={35}
								>
									<Mastercard />
								</svg>
							</div>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div
							style={{ textTransform: "uppercase", color: "rgba(255,255,255,0.65)" }}
						>
							Unsere Partner
						</div>
						<a
							href={
								countryCode === "DE"
									? "https://www.lionshome.de/"
									: "https://www.lionshome.at/"
							}
							target="_blank"
							rel="nooepener noreferrer"
						>
							<LionsHome style={{ height: 30, marginRight: 10, marginTop: 10 }} />
						</a>
						{/* <a
							href="https://www.idealo.de/preisvergleich/Shop/302818.html"
							target="_blank"
							rel="nooepener noreferrer"
						>
							<Idealo style={{ height: 30, marginRight: 10 }} />
						</a> */}
						<Image
							src={TrustedShopsExellentShopAward}
							alt="Trusted Shops Award"
							height={30}
						/>
					</Grid>
				</Grid>
				<div style={{ borderBottom: "1px solid white", marginTop: 50 }} />
				<div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
					© Tresoro - ein Shop der Secureo GmbH {new Date().getFullYear()}
				</div>
			</Container>
		</div>
	)
}

interface Props {
	countryCode: CountryCode
}

export default Footer
